import { usePageContext } from "vike-react/usePageContext";
import { localeDefault } from "~/lang";
import { useCookie } from "../composables/useCookie";

export default ({ to, locale, ...rest }) => {
  const pageContext = usePageContext();
  const [lang] = useCookie("lang");
  if (!locale) locale = pageContext.locale;
  if (!locale) locale = lang;
  if (!locale) locale = navigator?.language || navigator?.userLanguage;

  if (locale !== localeDefault.code) to = "/" + locale + (to === "/" ? "" : to);

  return <a href={to} {...rest} />;
};
